.bouncing-indicator {
  /*
 * Taken from https://codepen.io/viduthalai1947/pen/JkhDK?editors=1100
 */
  .modal-wrapper-outer {
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.85);
    flex-direction: column;
  }

  .copy {
    font-weight: 900;
  }

  .location_indicator {
    position: relative;
    width: 50px;
    height: 50px;
    vertical-align: middle;
  }

  .location_indicator:before,
  .location_indicator:after {
    position: absolute;
    content: '';
  }

  /* Marker */
  .location_indicator:before {
    left: 15px;
    width: 20px;
    height: 20px;
    border-radius: 100% 100% 100% 0;
    background-color: #000;
    -webkit-animation: mapping 1s linear infinite;
    -moz-animation: mapping 1s linear infinite;
    animation: mapping 1s linear infinite;
    -webkit-transform: rotate(-46deg);
    -moz-transform: rotate(-46deg);
    transform: rotate(-46deg);
  }

  /* Shadow below marker */
  .location_indicator:after {
    width: 30px;
    height: 10px;
    border-radius: 100%;
    left: 10px;
    background-color: rgba(223, 175, 55, 0.2);
    top: 24px;
    z-index: 0;
  }

  @keyframes mapping {
    0% {
      top: 0;
    }
    50% {
      top: -5px;
    }
    100% {
      top: 0;
    }
  }
}
