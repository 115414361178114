
.relocity-synergy-temp-housing.template {
    &.map .availability-modal {
        margin-top: 4vh;
    }

    #additionalInformation {
        cursor: default !important;

        .border-gray {
            border-color: gray;
        }
    }

    /* Slider */
    .keen-slider {
        &.unit-slider {
            height: auto;

            .unit {
                .unit__info {
                    .building-name {
                        color: #9b9b9b;
                        font-size: 15px;
                    }
                    .address {
                        color: black;
                    }
                }

                .unit__bedbath {
                    margin-top: 20px;
                    .beds,
                    .baths {
                        span {
                            font-weight: bold;
                            font-size: 14px;
                        }
                    }

                    .check-availability {
                        padding: 10px;
                        background-color: black;
                        border-radius: 5px;

                        span {
                            color: white;
                            font-size: 11px;
                        }

                        &:hover {
                            background-color: #333;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    /* Details */
    .details {
        cursor: default !important;

        .address {
            font-size: 18px;
            line-height: 25px;
            color: #505152;
        }
    }

    #map {
        height: 200px;
    }

    #marker {
        background-color: #22c17a;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        padding: 0px 15px;
        border: 1px solid white;

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - 5px);
            width: 0;
            border-top: 5px solid #22c17a;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }

    .availability-modal {
        .unit__info {
            text-align: center;
            margin-top: 20px;

            .building-name {
                color: #9b9b9b;
                font-size: 15px;
            }
            .address {
                color: black;
            }
        }
    }
}
