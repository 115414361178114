/**********************************
*
* Admin Dashboard - Users List
*  Starts from XS and then up to desktop.
*
**********************************/
.users-list-page {
    color: #000;
    margin-top: 35px;

    .user-list-row {
        padding: 0 10px;
    }

    .user-list-header {
        display: none;
    }

    .user-list-details-wrapper {
        cursor: pointer;
        color: #000;
        display: block;
        position: relative;
    }

    .user-list-column {
        background-color: #ffffff;
        margin-bottom: 10px;
        padding: 20px 60px 20px 20px;
        color: #000;
        text-decoration: none;
        display: block;
    }

    .user-role,
    .user-last-login {
        display: none;
    }

    .menu-icon-wrapper {
        display: inline-block;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        right: 35px;
    }

    .sub-menu {
        left: auto;
        right: 25px;
    }
}

/* Small viewport */
@media only screen and (min-width: 576px) {
    .users-list-page {
        .user-list-row {
            margin-bottom: 10px;

            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }

        .user-list-header {
            color: #9b9b9b;
            display: block;
            font-family: Avenir-lightOblique;
            margin-top: 60px;

            div {
                background-color: transparent;
                padding: 10px 10px 10px 20px;
            }
        }

        .user-role,
        .user-last-login {
            display: block;
        }

        .user-list-column {
            border: none;
            float: left;
            margin-bottom: 0;
            text-overflow: ellipsis;
            width: 30%;
            white-space: nowrap;
        }

        .menu-icon-wrapper {
            height: 100%;
            margin-right: -2px;
            text-align: right;
            width: 10%;
        }

        .menu-icon {
            height: 100%;
        }

        .sub-menu {
            right: 35px;
            top: 20px;
        }
    }
}
/* Medium viewport */
@media only screen and (min-width: 768px) {
    .users-list-page {
        .sub-menu {
            right: 100%;
            margin-right: -35px;
        }
    }
}
/* Large viewport */
@media only screen and (min-width: 1070px) {
    .users-list-page {
        .sub-menu {
            right: auto;
            left: 70px;
        }
    }
}
