
.relocity-school.template {
    /* Details */
    .details {
        h3 {
            font-size: 24px;
            font-weight: 500;
            color: #191919;

            span {
                font-size: 14px;
            }
        }

        padding: 20px;

        .address {
            font-size: 18px;
            line-height: 25px;
            color: #505152;
        }

        .quick-info {
            display: flex;
            justify-content: space-around;
            margin-top: 30px;
        }
    }

    .description {
        padding: 20px;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
    }

    #map {
        height: 200px;
    }

    #marker {
        background-color: #5d615f;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        padding: 0px 15px;
        border: 1px solid white;

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - 5px);
            width: 0;
            border-top: 5px solid #5d615f;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }

    .sticky.footer {
        position: sticky;
        bottom: 0;
        background: white;
        box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.25);
        padding: 28px;
        z-index: 10;

        a {
            margin: auto;
            text-align: center;
            background: black;
            color: white;
            display: block;
            padding: 10px;
            width: 100%;
            border-radius: 21px;
        }
    }
}
