
.relocity-schools.template {
    .force-no-mb {
        margin-bottom: 0px !important;
    }

    /* Header */
    header {
        min-height: 40px;
        padding: 20px;
    }

    /* Details */
    .details {
        padding: 20px;
        cursor: default !important;
    }

    .icon-wrapper {
        border-radius: 9999px;
        height: 1.75rem;
        width: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #23b899;
    }

    .icon-size {
        height: 1rem;
        width: 1rem;
    }

    .font-1 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 1.2rem;
    }

    .font-2 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
    }

    .font-3 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
    }

    .primary-text-color {
        color: #505152;
    }

    .secondary-text-color {
        color: #9b9b9b;
    }

    .accent-text-color {
        color: #fff;
    }

    .over {
        top: -45%;
    }

    // half black half white
    .hbhw {
        background-image: linear-gradient(to top, #000000 0%, #000000 50%, #ffffff 50%, #ffffff 100%);
    }
}
