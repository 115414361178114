.tns-controls {
	display: none;
}

.tns-nav {
  	text-align: center;
  	margin: 10px 0;

  	& > [aria-controls] {
	  width: 9px;
	  height: 9px;
	  padding: 0;
	  margin: 0 5px;
	  border-radius: 50%;
	  background: #ddd;
	  border: 0;
	}

	& > .tns-nav-active { background: #999; }
}