
.relocity-shuttle-stop.template {
    /* Details */
    .details {
        padding: 10px;

        .address {
            font-size: 18px;
            line-height: 25px;
            color: #505152;
        }
    }

    .description {
        padding: 10px;
        font-size: 14px;
        line-height: 22px;
        color: #9b9b9b;
    }

    #map {
        height: 200px;

        i.fa-shuttle-van {
            --fa-secondary-color: dimgray;
        }
    }
}
