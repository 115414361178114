
.mini-detail-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.area-tour-number {
    top: 6px;
    left: 18px;
}
