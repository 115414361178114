@import '~nouislider/distribute/nouislider.min.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '~swiper/swiper';
@import '~tiny-slider/dist/tiny-slider.css';
@import '~vue-datetime/dist/vue-datetime.css';

/**********************************
 *
 * Global Imports
 *
 **********************************/

@import './global/resets';
@import './global/variables';
@import './global/fonts';
@import './global/utilities';
@import './global/pagination';
@import './global/sass-utils';
@import './global/components';
@import './global/tiny-slider';
@import './global/toasted';
@import './global/transitions';
@import './global/tailwind-tiny';

/**********************************
 * Custom CSS for pages
 * TODO: Move into custom links
 * in each page,instead of
 * importing all pages here.
 **********************************/

@import './home';
@import './maps-list';
@import './users-list';
@import './map';
@import './layer-details';
@import './layers';
@import './modal-edit';

/**********************************
 * App Styles
 * Starts from XS and then up to desktop.
 **********************************/

body {
  margin: 0;
}

html {
  background: $app-background-color;
}

body {
  font-family: Avenir-light;
}

h2 {
  font-family: Avenir-lightOblique;
  color: $brown-grey;
  font-size: 18px;
  font-weight: 400;
}

a {
  text-decoration: none;
  &:visited {
    color: $brown-grey;
  }
  &:hover {
    color: $brown-grey;
    text-decoration: underline;
  }
  &:active {}
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// Remove blue outline on focus (Chrome/Safari)
input:focus,
select:focus,
textarea:focus,
button:focus,
canvas {
  outline: none;
}


/********************************************************************
 * Inputs, labels for inputs, and select fields
 ********************************************************************/

label {
  display: block;
  font-family: Avenir-bookOblique;
}

select {
  border-radius: 13.5px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  height: 30px;
  margin-bottom: 20px;
  padding-left: 14px;
  width: 100%;
  background: #fff;
  font-size: 13px;
  &::placeholder {
    color: #9b9b9b;
    opacity: 1;
  }
}

select,
input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='range'] {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}


/*@mixin track() {
  background: $off-white;
  background: red;
  height: 2px;
}

input {
  &::-webkit-slider-runnable-track { @include track }
  &::-moz-range-track { @include track }
  &::-ms-track { @include track }
}*/

select {
  position: relative;
  background: url(#{$distImagesDir}down-arrow.svg) 95% center no-repeat;
  background-size: 10px 8px;
  font-size: 13px;
}

.square-select {
  select {
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
    height: auto;
    line-height: 1.25rem;
    margin-bottom: 0;
    padding: 0.5rem 0.75rem;
  }
}

// Override noUiSlider styles
html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
  right: -20px;
}

.noUi-horizontal {
  height: 1px;
  border-color: #ebebeb;
  .noUi-handle {
    background: url(#{$distImagesDir}slider-handle.svg) center no-repeat;
    background-size: 40px 20px;
    width: 40px;
    height: 20px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    top: -11px;
    &:before,
    &:after {
      width: 0;
    }
    &:hover {
      cursor: grab;
    }
    &:active {
      cursor: grabbing;
    }
  }
  .noUi-tooltip {
    color: $brown-grey;
    bottom: -170%;
    border: none;
    background: transparent;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 900;
    font-style: oblique;
  }
  .noUi-value {
    display: none;
  }
}

.tooltip {
  z-index: 999;
}

.noUi-pips-horizontal {
  padding-top: 6px;
}

.vue-snotify {
  position: relative;
  z-index: 999999;
}


/********************************************************************
 * App Styles
 ********************************************************************/

#vue {
  .nav {
    background-color: #fff;
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    padding: 55px 15px 0;
    text-align: center;
    width: 100%;
    z-index: 30;
    .header-nav-left {
      text-align: left;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;

      .logo {
        width: fit-content;

        img {
          width: 100%;
        }
      }
    }
    h1 {
      font-weight: 300;
      text-transform: uppercase;
    }
    p {
      font-family: Avenir-lightOblique;
      display: none;
    }
    .menu-icon-wrapper {
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      .menu-icon {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .outer {
    max-width: 940px;
    margin: 0 auto;
    padding: $nav-bar-height-mobile 5px 30px;
    .inner-container {
      display: flex;
      flex-grow: 1;
    }
    //Hide toolbar on loader
    .modal-wrapper-outer+.map-toolbar {
      display: none;
    }
    .map-toolbar {
      font-size: 0.75rem;
      height: auto;
      margin-top: 15px;
      padding: 0;
      text-align: center;
      h2 {
        padding-bottom: 15px;
        text-align: center;
      }
      .search-bar-wrapper {
        display: inline-block;
        height: 30px;
        position: relative;
        margin: 0 5px 15px;
        width: 85%;
        .search-icon {
          color: $brown-grey;
          left: 11px;
          position: absolute;
          top: 10px;
        }
        input {
          border: none;
          border-radius: 9999px;
          box-shadow: 0 0 4px 0 rgba(206, 206, 206, 0.5);
          color: $brown-grey;
          height: 100%;
          padding-left: 30px;
          width: 100%;
        }
      }
    }
    main.content-container {
      margin: 20px -10px 0;
      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
  }
  /* Small viewport */
  @media only screen and (min-width: 576px) {
    nav.nav {
      height: $nav-bar-height;
      padding-top: 0;
      position: relative;
      text-align: center;
      vertical-align: middle;
      p {
        display: inline-block;
      }
      .menu-icon-wrapper {
        line-height: normal;
      }
    }
    .outer {
      padding: 35px 10px 105px;
      .map-toolbar {
        align-items: flex-start;
        display: flex;
        font-size: 0.75rem;
        justify-content: space-between;
        margin-top: 50px;
        padding: 0;
        .search-bar-wrapper {
          flex-basis: 50%;
          flex-grow: 0;
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          margin-bottom: 0;
          position: relative;
          .search-icon {
            left: 15px;
          }
          input {
            border: none;
            border-radius: 9999px;
            color: $brown-grey;
            height: 100%;
            padding-left: 2.5rem;
            width: 100%;
          }
        }
      }
    }
  }
}

// Hide items in desktop view
.layer-details-mobile-view,
.panel.panel-control.close-mobile-layers-panel,
.mobile-layers-panel-toggle {
  display: none;
}

.mapboxgl-canvas {
  width: 100% !important;
  height: 100% !important;
}


/********************************************************************
 * Mobile View
 ********************************************************************/

@media (max-width: 767px) {
  #vue.map-view {
    padding-top: $nav-bar-height-mobile;
    #nav-container .header-title {
      display: block;
      line-height: normal;
    }
    #nav {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      padding: 0 15px 10px;
      height: $nav-bar-height-mobile;
      .header-nav-left {
        position: relative;
        top: 0;
        left: 0;
        transform: none;

        .logo {
          margin-left: auto;
          margin-right: auto;
          min-width: 100%;
        }
      }
    }
  }
  .layer-details-desktop-view {
    display: none;
  }
  .layer-details-mobile-view {
    display: block;
  }
  .layer-details-mobile-view {
    .layer-details {
      display: block;
      bottom: 0;
      height: auto;
      overflow: hidden;
      padding: 10px;
      position: relative;
      width: 100%;
      z-index: 100;
      transition: all 0.25s ease-in;
      &.expanded {
        flex: 1 0 100%;
        position: absolute;
        top: 0;
        left: 0;
        .panel-control {
          display: flex;
        }
        // unhide specific details on the expanded details view:
        .feature-details-wrapper p,
        .link-preview {
          display: block;
        }
        .feature-details-wrapper {
          display: block;
        }
        .great-schools-mobile {
          display: none;
        }
        .feature-description {
          overflow-x: visible;
          overflow-y: scroll;
          white-space: normal;
        }
        .great-schools-logo {
          display: block;
        }
      }
      .feature {
        margin-bottom: 0;
        padding: {
          bottom: 10px;
          top: 10px;
        }
      }
      .feature-details-wrapper {
        display: block;
        width: 100%;
      }
      .great-schools-mobile {
        width: 55px;
        height: 22px;
        margin-left: auto;
      }
      // Hide details on the abbreviated details view:
      .feature-details-wrapper p,
      .link-preview {
        display: none;
      }

      .feature-details-wrapper .template p {
        display: block;
      }

      .feature-description {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .great-schools-logo {
        display: none;
      }
    }
  }
  .panel.panel-control.close-mobile-layers-panel {
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: text;
    display: flex;
    text-align: right;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .panel-control .panel-control-header {
    color: $black;
    font-family: Avenir-light;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  .close-mobile-panel {
    cursor: pointer;
    display: inline-block;
    background: url(#{$distImagesDir}close-icon.svg) right center no-repeat;
    background-size: 25px;
    height: 44px;
    width: 44px;
  }
  .outer-container.map-page .inner-container {
    display: flex;
    flex-direction: column;
    // All Layers Panel
    .layers-wrapper {
      background: $off-white;
      display: block;
      height: 100%;
      position: relative;
      z-index: 101;
      padding: 20px;
      width: 100%;
      max-width: unset;
      .layer-container {
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
        padding: 0 20px;
        .layer {
          margin-bottom: 0;
          .layer-header-wrapper {
            padding-left: 10px;
            cursor: text;
            box-shadow: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            background-color: $white;
            margin-bottom: 0;
            .panel-item-header {
              color: $brown-grey;
              font-family: Avenir-light;
              font-size: 14px;
              line-height: 1rem;
              padding: 20px 0;
            }
          }
          &:last-of-type .layer-header-wrapper {
            border-bottom: none;
          }
        }
      }
    }
    .layer-details-mobile-view .layers-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    // Map Section:
    #map-container {
      position: relative;
      flex: 1 0 100%;
      right: auto;
      top: auto;
      bottom: auto;
      left: auto;
      &.show-mobile-details-panel {
        padding-left: 0;
      }
    }
    .mapboxgl-map {
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
  .mobile-layers-panel-toggle {
    display: inline-block;
  }
  .desktop-layers-panel-toggle {
    display: none;
  }
}

.daterangepicker {
  min-width: 0 !important;
}

button.disabled {
  opacity: 0.5;
  &:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
  }
}

button.loading {
  opacity: 0.5;
  &:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: wait;
  }
}

.modal-title {
  font-family: Avenir;
  font-size: 24px;
  font-weight: 500;
  color: #3b3b3b;
  text-align: left;
  margin-bottom: 10px;
}

.hidden {
  display: none !important;
}

.feature {
  cursor: pointer;
}

.clickable {
  cursor: pointer;

  &:hover {
      color: #9b9b9b;
  }
}

.multiselect {
   border: none;
}

.border-1 {
  border-width: 1px;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-14 {
  bottom: 3.5rem;
}
.top-3 {
  top: .75rem;
}
.right-4 {
  right: 1rem;
}
@import "./includes";
