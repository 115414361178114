@font-face {
    font-family: 'Avenir-lightOblique';
    src: url('/fonts/Avenir/Avenir-LightOblique.woff2') format('woff2'),
    url('/fonts/Avenir/Avenir-LightOblique.woff') format('woff'),
    url('/fonts/Avenir/Avenir-LightOblique.ttf')  format('truetype'),
}

@font-face {
    font-family: 'Avenir-bookOblique';
    src: url('/fonts/Avenir/Avenir-BookOblique.woff2') format('woff2'),
    url('/fonts/Avenir/Avenir-BookOblique.woff') format('woff'),
    url('/fonts/Avenir/Avenir-BookOblique.ttf')  format('truetype'),
}

@font-face {
    font-family: 'Avenir-light';
    src: url('/fonts/Avenir/Avenir-Light.woff2') format('woff2'),
    url('/fonts/Avenir/Avenir-Light.woff') format('woff'),
    url('/fonts/Avenir/Avenir-Light.ttf')  format('truetype'),
}
