*,
*:after,
*:before,
:focus {
    box-sizing: border-box;
    outline:0;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, figure, p, pre {
    margin: 0;
}

button, input {
    overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * Reset button styles.
 * It takes a bit of work to achieve a neutral look.
 */
button, optgroup, select {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;

    &:focus,
    &:active {
        outline: 0;
    }
}
button,
input,
optgroup,
select,
textarea,html input[type="button"],
input[type="reset"],
input[type="submit"],button[disabled],
html input[disabled],button::-moz-focus-inner,
input::-moz-focus-inner, input[type="checkbox"],
input[type="radio"], input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button, input[type="search"], input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    border:none;
    background-image:none;
    background-color:transparent;
    // TODO Remove once autoprefixer is installed.
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
