/**********************************
 *
 * Global Variables.
 * Please keep in alpha order.
 *
 **********************************/

/**********************************
 * Config
 **********************************/
$distImagesDir: '/images/';

/**********************************
 * Colors
 **********************************/
$beige: #d3cfc2;
$black: #141414;
$forrest-green: #429326;
$grey: #505152;
$light-grey: #CCCCCC;
$brown-grey: #9b9b9b;
$off-white: #fafafa;
$orange: #fe8354;
$white: #ffffff;
$soft-black: #000000d4;

/**********************************
 * Element Variables
 **********************************/
$add-panel-item-header-color: $black;
$app-background-color: $off-white;
$button-group-bg-color: $light-grey;
$button-group-text-hover: $white;
$nav-bar-height-mobile: 100px;
$nav-bar-height: 65px;
$panel-item-header-color: $black;
$panel-item-subheader-color: $grey;
$pill-btn-color: $white;
$menu-hover-color: $light-grey;
$sub-menu-hover-color: $orange;
