/**********************************
 * Utility Classes
 * Please keep in Alpha Order.
 **********************************/
.align-self-start {
    align-self: start !important;
    width: 100%
}

.flex-grow-1 {
    display: flex;
    flex-grow: 1;
}

.flex-basis-auto {
    flex-basis: auto !important;
}

.flex-basis-100 {
    flex-basis: 100% !important;
}

.hide {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.h-100 {
    height: 100%;
}

.hidden {
    display: none;
}

.v-align-middle {
    vertical-align: middle;
}

.visiblity-hidden {
    visibility: hidden;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/**********************************
 * Form Utility Classes
 * Please keep in Alpha Order.
 **********************************/
.invalid {
    box-shadow: inset 0px 0px 0px 1px red;
}
