.header-wrapper {

  width: 100%;
  display: grid;
  grid-template-columns: 70px 100px minmax(0,1fr) 100px 80px;
  align-items: center;
  margin-bottom: 7px;

  .header-label {
    display: flex;
    font-family: Avenir-Medium;
    font-size: 18px;
    font-weight: 500;
    color: #3b3b3b;
  }

  .reorder {
    margin-left: 5px;
  }

  .thumb {
    margin-left: 11px;
  }

  .option {
    margin-left: auto;
  }
}
