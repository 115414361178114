
.availability-modal {
    .timezone {
        text-align: center;
        color: #9b9b9b;
        font-size: 15px;
        margin-bottom: 0;
    }

    .calendar-header {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        margin-top: 50px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eaeaea;

        span {
            text-align: center;
            color: #747474;
        }
    }

    .availability-calendars {
        height: 50vh;
        overflow-y: scroll;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .availability-calendar {
            margin-bottom: 50px;
        }
    }
}
