
.availability-calendar {
    font-family: 'Avenir';

    h3 {
        margin-left: 10px;
        font-weight: 500;
        font-size: 21px;
    }

    .calendar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        text-align: center;

        .date {
            padding: 10px 0;

            &.blocked span {
                text-decoration: line-through;
                color: #b0b0b0;
            }
        }
    }
}
