/**********************************
 * Custom CSS for maps list
 **********************************/

#nav-container {
    height: $nav-bar-height;

    #nav {
        height: $nav-bar-height;
    }

    .header-title {
        line-height: $nav-bar-height;
    }

    &.has-breadcrumbs {
        height: 80px;

        #nav {
            height: 80px;
            z-index: 20;
        }

        .header-title {
            line-height: 80px;
        }
    }
}

#vue {
    height: 100%;

    #nav {
        position: fixed;
        top: 0;
        left: 0;

        .preview-map-btn {
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
            background-color: $off-white;
            color: $black;
            font-size: 14px;
            font-weight: 500;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 50px;

            &:after {
                background: url(#{$distImagesDir}view-icon-on.svg) center no-repeat;
            }
        }
    }
    .outer.layers-page {
        height: 100%;
        padding: 20px;
        max-width: 100%;
        display: flex;
        flex-grow: 1;

        .inner-container {
            height: 100%;
            position: relative;

            .layers {
                height: 100%;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding-right: 20px;
                flex: 0 0 440px;
                overflow-y: scroll;

                .destination-input-wrapper {
                    position: relative;

                    .search-icon {
                        left: 11px;
                        position: absolute;
                        top: 10px;
                    }

                    input {
                        padding-left: 30px;
                    }
                }

                .panel-item {
                    // Houses panel
                    &.houses-layer-details {
                        .expanded-subheader-wrapper {
                            align-items: center;
                            display: flex;
                            justify-content: space-between;
                            padding-bottom: 12px;

                            .expanded-subheader {
                                color: $black;
                                padding: 0;
                            }

                            .add-icon {
                                background: grey url(#{$distImagesDir}plus-icon.svg) center no-repeat;
                                background-size: 25px;
                                height: 44px;
                                margin-top: -6px;
                                width: 44px;
                            }
                        }
                    }
                }
            }

            .map-panel {
                box-shadow: -4px 2px 4px 0 rgba(0, 0, 0, 0.1);
                flex: 1 1 auto;
                position: relative;

                #map {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }

 /**************************************
 * Custom CSS for temp housing
 **********************************/
    .temp-housing-feature {
        .image-details-wrapper {
            display: flex;
            .feature-image {
                margin-right: 16px;
                img {
                    width: 94px;
                    height: 96px;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
            .feature-details-wrapper {
                margin: 0 7px 0 3px;
                @media only screen and (max-width: 767px) {
                    display: block;
                }

                .feature-header {
                    font-family: Avenir-Medium;
                    font-size: 20px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #141414;
                }

                .feature-misc-details {
                    @media only screen and (max-width: 767px) {
                        display: block;
                    }
                    span.star-rating {
                        color: #141414 !important;
                        margin-right: 7px;
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }

                .feature-last-row {
                    padding: 0 10px 5px 0;
                    .feature-description {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                }
            }
        }
    }

    .temp-housing-edit-wrapper {
        margin-top: 20px;
        .lock-toggle {
            width: 14px;
            height: 19px;
            margin-right: 10px;
        }
        .ready-only-date-wrapper {
            width: 190px;
            height: 30px;
            opacity: 0.67;
            border-radius: 3px;
            border: solid 1px rgba(151, 151, 151, 0.1);
            background-color: #ffffff;
            margin-bottom: 13px;
            .read-only-date {
                width: 159px;
                height: 15px;
                font-family: Avenir-Book;
                font-size: 14px;
                color: #141414;
                text-align: center;
                margin: 0 auto;
                padding: 6px 0;
            }
        }
        .disabled-prop {
            cursor: default;
        }
    }


    /* Small viewport */
    @media only screen and (min-width: 576px) {
            #nav {

            }
    }

    /* Medium viewport */
    @media only screen and (min-width: 768px) {

    }

    /* Large viewport */
    @media only screen and (min-width: 992px) {

    }

    /**/

    /* Date Range Picker and Multi Select styling for EditTempHousingPanel */

    .vue-daterange-picker {
        margin-bottom: 20px;
    }
    .daterangepicker td.active.in-range,
    .daterangepicker td.active.in-range:hover {
        background-color: #141414;
        color: #fff;
    }

    .daterangepicker td.in-range {
        background-color: #fafafa;
    }

    .multiselect__tag {
        background: #141414;
        color: #ffffff;
    }
    .multiselect__tag-icon:after {
        color: #ffffff;
    }
    .multiselect__tag-icon:hover {
        background: #000;
    }
    .multiselect__option--highlight {
        background: #141414;
        color: #ffffff;
    }
    .multiselect__option--highlight:after {
        background: #141414;
        color: #ffffff;
    }
    .multiselect__option--selected.multiselect__option--highlight {
        background: #141414;
        color: #ffffff;
    }
    .multiselect__option--selected.multiselect__option--highlight:after {
        background: #141414;
        color: #ffffff;
    }
}
