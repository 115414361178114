.maps-list-page {
    &.no-results p {
        text-align: center;
        padding: 50px 0;
    }
}

.maps-list-page .map-outer-wrapper {
    border-radius: 4px;
    float: left;
    padding: 10px;
    width: 100%;
}

.maps-list-page .map-inner-wrapper {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(206, 206, 206, 0.5);
    position: relative;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 11px -1px $black;
    }
}

.maps-list-page .map-top {
    align-items: center;
    background: #fff;
    border-radius: 4px 4px 0 0;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 5px 10px;
    position: relative;
}

.maps-list-page .map-top p {
    color: #4b4b4b;
    font-size: 13px;
    overflow: hidden;
    padding-right: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.maps-list-page .map-image {
    background-size: cover;
    background-position: center;
    border-radius: 0 0 4px 4px;
    padding-top: 31.818181%;
}

.maps-list-page .menu-icon-wrapper {
    right: -10px;
    top: -3px;
    z-index: 20;

    &:hover .sub-menu {
        display: block;
    }

    .sub-menu {
        left: auto;
        right: 25px;
    }
}

/* Small viewport */
@media only screen and (min-width: 576px) {
    .maps-list-page {
        margin-top: 40px;

        .map-outer-wrapper {
            width: 50%;

            &:nth-child(odd) {
                .map-inner-wrapper .menu-icon-wrapper .sub-menu {
                    left: 40px;
                    right: auto;
                }
            }
        }
    }
}

/* Medium viewport */
@media only screen and (min-width: 768px) {
    .maps-list-page {
        .map-outer-wrapper {
            width: 33.33%;

            &:nth-child(1n) {
                .map-inner-wrapper .menu-icon-wrapper .sub-menu {
                    left: 40px;
                    right: auto;
                }
            }

            &:nth-child(3n){
                .map-inner-wrapper .menu-icon-wrapper .sub-menu {
                    left: -110px;
                }
            }
        }
    }
}

/* Large viewport */
@media only screen and (min-width: 1024px) {
    .maps-list-page {
        .map-outer-wrapper {
            width: 25%;

            .map-inner-wrapper:nth-child(1n) .menu-icon-wrapper .sub-menu {
                left: 100%;
            }

            &:nth-child(4n){
                .map-inner-wrapper .menu-icon-wrapper .sub-menu {
                    left: -110px;
                }
            }
        }
    }
}

/* Extra Large viewport */
@media only screen and (min-width: 1330px) {
    .maps-list-page {
        .map-outer-wrapper {
            width: 25%;

            &:nth-child(1n) .map-inner-wrapper .menu-icon-wrapper .sub-menu {
                left: 100%;
            }
        }
    }
}

