/********************************************************************
 *
 * Components
 * Starts from XS and then up to desktop.
 *
 * TODO: Turn these into SASS Placeholders;
 *
 ********************************************************************/
.text-only-btn {
    color: $brown-grey;
    font-size: 13px;
    margin-right: 15px;

    &:hover {
        text-decoration: underline;
    }
}

.school-level-input-container select {
    margin-bottom: 0;
}

.school-type-input-container {
    margin: 0 0 20px;
}

.link-preview {
    margin-bottom: 15px;

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
}

.custom-icon-input-container {
    display: flex;
    margin: 15px 0 30px;
    align-items: center;

    >img {
        height: 52px;
        width: auto;
        margin-right: 15px;
    }

    >div {
        flex-grow: 1;

        label {
            font-family: Avenir;
            font-size: 13px;
            color: #4a4a4a;
        }

        select {
            margin-bottom: 0;
        }
    }
}

.commute-radius-profiles {
    margin: 30px 0;
}

.logo {
    width: 25%;
    img {
        width: 100%;
    }
}

.breadcrumbs {
    margin-top: 5px;
    color: #000;

    a {
        color: #d3cfc2;

        &:hover {
            color: #fe8354;
            text-decoration: none;
        }
    }

    a:before,
    span:before {
        content: ' / ';
        color: #d3cfc2;
    }

    a:first-of-type:before {
        content: '/ ';
    }
}

.snotify {
    width: 500px;
}

.snotify-centerTop,
.snotify-centerCenter,
.snotify-centerBottom {
    left: calc(50% - 500px / 2);
    ;
}

.snotifyToast {
    border-radius: 4px;
    box-shadow: 0 2px 45px 20px rgba(0, 0, 0, 0.1);
}

.snotifyToast__inner {
    padding: 0 !important;
    min-height: 0 !important;
    border-radius: 0 !important;
    display: block !important;
}

.error-toast {
    position: relative;
    background-color: #ff7f7e;
    color: #fff;
    text-align: left;
    padding: 15px 45px 15px 15px;
    font-size: 22px;
    font-weight: 900;

    &:after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background: url(#{$distImagesDir}close-button-white.svg) center no-repeat;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}

.info-toast {
    position: relative;
    background-color: #fff;
    color: #000;
    text-align: left;
    padding: 15px 45px 15px 15px;
    font-size: 20px;
    font-weight: 500;

    &:after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background: url(#{$distImagesDir}close-button-white.svg) center no-repeat;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}

.annotation-panel-back-btn {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 0 10px !important;
    align-items: center;
    height: 40px;

    &:before {
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        background: url(#{$distImagesDir}back-icon.svg) center no-repeat;
        background-size: 20px 20px;
        margin-right: 10px;
    }
}

.annotation-panel-header {
    font-family: 'Avenir-lightOblique';
    font-size: 18px;
    color: #000;
    text-align: center;
    margin-bottom: 10px;
}

.annotation-panel-outer {
    background: #fafafa;
    position: absolute;
    top: 0;
    left: 0;
    width: 420px;
    bottom: 0;
    overflow-y: scroll;
    z-index: 50;
}

.annotation-panel {
    .panel-group form {
        padding: 10px;
        border-radius: 4px;
        background-color: #fafafa;

        #description-input {
            padding-top: 10px;
            min-height: 180px;
        }
    }
}

.expanded-subheader-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 10px 0;

    .expanded-subheader {
        padding-bottom: 0 !important;
    }
}

.add-icon {
    width: 25px;
    height: 25px;
    background: url(#{$distImagesDir}plus-icon-gold-outline.svg) center no-repeat;
}


/********************************************************************
 * 3 dot (⋮) menu icon button
 *
 * .menu-icon-wrapper's absolute positioning will have to be changed
 * on your page's custom scss file.
 ********************************************************************/
.menu-icon-wrapper {
    display: inline-block;
    position: absolute;
    top: -20px;
    right: -26px;
    /*
     * As a best practice, keep height and
     * width at 44px minimum.
     */
    height: 44px;
    width: 44px;

    &:hover .sub-menu {
        display: block;
    }

    .menu-icon {
        background: url(#{$distImagesDir}menu-icon.svg) center no-repeat;
        background-size: 3px 15px;
        display: inline-block;
        height: 100%;
        width: 100%;
    }

    .sub-menu {
        background: $white;
        color: $brown-grey;
        display: none;
        position: absolute;
        text-align: center;
        z-index: 100;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(206, 206, 206, 0.5);
        top: 10px;
        left: 100%;
        margin-left: -15px;

        &.left {
            left: auto;
            right: 100%;
            margin-left: 0;
            margin-right: -15px;
        }

        & a {
            color: $brown-grey;

            &:hover {
                text-decoration: none;
                color: $sub-menu-hover-color;
            }
        }

        .sub-menu-option {
            display: block;
            font-size: 14px;
            padding: 5px 24px;
            position: relative;
            text-align: center;
            width: 100%;
            white-space: nowrap;
            border-bottom: 1px solid rgba(211, 207, 194, 0.1);
            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }

            &:hover,
            &:hover a {
                color: $sub-menu-hover-color;
            }
        }
    }
}

/********************************************************************
 * Pill shaped button, for items such as "Add Layer" or "Add User".
 *
 * Include .pill-btn-icon to .pill-btn element if the icon is needed.
 *
 * var $pill-btn-height = Control the height of the default button,
 * icon, and centering of the text, by changing the px.
 ********************************************************************/
$pill-btn-height: 30px;

.pill-btn {
    color: $pill-btn-color;
    font-size: 13px;
    height: $pill-btn-height;
    line-height: $pill-btn-height;
    padding-right: 30px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    background-color: $black;
    border-radius: 16px;
    padding: 0 20px;

    &:hover {
        border-radius: 16px;
        box-shadow: 0 0 5px -1px $black;
        cursor: pointer;
    }

    .pill-btn-text {
        display: inline-block;
    }

    &.pill-btn-icon {
        padding: 0 40px 0 20px;
        text-align: left;

        &:after {
            background: url(#{$distImagesDir}plus-button.svg) center no-repeat;
            background-size: 30px;
            content: '';
            display: inline-block;
            height: $pill-btn-height;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            vertical-align: middle;
            width: $pill-btn-height;
        }
    }
}

.btn-full {
    width: 100%;
}

/* xs viewport */
@media only screen and (max-width: 576px) {
    .map-toolbar {
        margin-bottom: 20px;
    }
}

/********************************************************************
 * Panels (Ex: On desktop, the panels on the left side)
 ********************************************************************/
.panel-group-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .panel-group {
        background: #fff;
        border: solid 1px rgba(211, 207, 194, 0.49);
        border-radius: 4px;
        padding: 10px;

        &:last-of-type {
            flex-grow: 1;
        }

        .panel-item {
            background-color: $off-white;
            border-radius: 4px;
            padding: 15px;
            margin-bottom: 15px;

            /* TODO: Both :hover states only for testing. Remove once JS functionality is implemented; */
            // &:hover .expanded-state {display: block;}
            // &:hover .panel-item-subheader {display: none;}

            .panel-item-header-wrapper {
                position: relative;
                vertical-align: middle;
                display: flex;
                justify-content: space-between;
                align-items: center;

                input {
                    margin-bottom: 0;
                    margin-left: 30px;
                }

                .view-panel-item-icon {
                    background: url(#{$distImagesDir}view-icon-off.svg) center no-repeat;
                    background-size: 16px 11px;
                    content: '';
                    display: inline-block;
                    height: 44px;
                    left: 0;
                    position: absolute;
                    margin-left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 44px;

                    &.layer-is-active {
                        background-image: url(#{$distImagesDir}view-icon-on.svg);
                    }
                }

                .panel-item-header {
                    color: $panel-item-header-color;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 500;
                    padding-left: 47px;
                    margin-right: auto;
                    height: 30px;
                    line-height: 30px;
                }
            }

            .panel-item-subheader {
                color: $panel-item-subheader-color;
                font-size: 12px;
                font-weight: 300;
                white-space: pre-wrap;

                &.is-expanded {
                    display: none;
                }
            }

            .expanded-state {
                // display: none;

                &.is-expanded {
                    display: block;
                }

                .expanded-subheader {
                    color: $panel-item-subheader-color;
                    font-size: 12px;
                    font-weight: 300;
                    padding-bottom: 12px;
                }

                .subpanel {
                    // background: {
                    //     color: $white;
                    //     image: url(#{$distImagesDir}marker-icon.svg);
                    //     size: 14px 18px;
                    //     position: 10px center;
                    //     repeat: no-repeat;
                    // }

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 5px;
                    box-shadow: 0px 1px 3px #ccc;
                    // padding-left: 36px;

                    &.large {
                        background: {
                            size: 25px 25px;
                        }

                        ;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    .close-btn {
                        background: url(#{$distImagesDir}close-button.svg) right no-repeat;
                        background-size: 19px;
                        height: 44px;
                        width: 44px;
                        margin-right: 10px;
                    }
                }
            }

            .button-group {
                width: 100%;
            }
        }
    }

    .add-panel-item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        height: 30px;

        &:before {
            background: url(#{$distImagesDir}layers-icon.svg) center no-repeat;
            content: '';
            display: inline-block;
            width: 30px;
            height: 30px;
            flex-basis: 55px;
        }

        .add-panel-item-header {
            flex-grow: 2;
            color: $add-panel-item-header-color;
        }
    }
}

/********************************************************************
 * Used for items such as pagination, and filter buttons
 ********************************************************************/
.button-group {
    align-content: stretch;
    color: #9b9b9b;
    display: flex;

    font: {
        family: Avenir-lightOblique;
        size: 12px;
        weight: 900;
    }

    justify-content: space-between;
    margin-top: 15px;
    text-align: center;

    div {
        cursor: pointer;

        border: {
            top: 1px;
            right: 0;
            bottom: 1px;
            left: 1px;
            style: solid;
            color: #9b9b9b;
        }

        min-width: 43px;
        padding: 5px 10px;
        width: 100%;

        &:first-of-type {
            border-radius: 5px 0 0 5px;
        }

        &:last-of-type {
            border-radius: 0 5px 5px 0;
            border-right: 1px solid #9b9b9b;
        }

        &.active {
            background-color: $button-group-bg-color;
            color: $button-group-text-hover;
        }
    }

    /* Small viewport */
    @media only screen and (min-width: 576px) {
        .pagination-wrapper {
            // margin-top: 68px;
        }
    }
}

/********************************************************************
 * Address Search Autocomplete/Suggestions
 ********************************************************************/
.autocomplete {
    &.invalid {
        border-radius: 13.5px;
        margin-bottom: 20px;

        .autocomplete__box {
            background-color: transparent;
            box-shadow: none;
            margin-bottom: 0;
        }
    }
}

.autocomplete__inputs input {
    padding: 0 !important;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    background: transparent;
}

.autocomplete__box {
    border-radius: 13.5px !important;
    border: none !important;
    padding: 0 8px !important;
    margin-bottom: 20px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.autocomplete__results {
    margin: -20px 0 0 !important;
    border: 1px solid #ccc !important;
    border-radius: 13.5px;
    text-align: left;
}

.autocomplete--clear img {
    display: block;
}

.noUi-horizontal {
    margin: 30px 15px 40px;
}
