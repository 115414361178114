
.relocity-housing.template {
    /* Header */
    header {
        min-height: 40px;

        .badge {
            position: absolute;
        }

        .rent-or-sale.badge {
            background-color: #23b899;
            color: white;
            padding: 3px 8px;
            bottom: 10px;
            left: 10px;
            z-index: 10;
            font-size: 12px;
            border-radius: 8px;
        }

        .gallery-page-num.badge {
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            padding: 3px 8px;
            bottom: 10px;
            right: 10px;
            z-index: 10;
            font-size: 12px;
            text-shadow: 1px 1px 1px black;
            border-radius: 8px;
        }
    }

    /* Details */
    .details {
        padding: 20px;
        cursor: default !important;

        .pricing-and-logo {
            display: flex;
            justify-content: space-between;

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: #191919;

                span {
                    font-size: 14px;
                }
            }

            .provider-logo {
                height: 40px;

                &.zumper {
                    height: 20px;
                }
            }
        }

        .address {
            font-size: 18px;
            line-height: 25px;
            color: #505152;
        }

        .beds-baths-sqft {
            display: flex;
            justify-content: space-around;
            margin-top: 30px;
        }
    }

    .description {
        padding: 20px;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
    }

    #map {
        height: 200px;
    }

    #marker {
        background-color: #22c17a;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        padding: 0px 15px;
        border: 1px solid white;

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - 5px);
            width: 0;
            border-top: 5px solid #22c17a;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }

    .sticky.footer {
        position: sticky;
        bottom: 0;
        background: white;
        box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.25);
        padding: 28px;
        z-index: 10;

        a {
            margin: auto;
            text-align: center;
            background: black;
            color: white;
            display: block;
            padding: 10px;
            width: 100%;
            border-radius: 21px;
        }
    }
}
