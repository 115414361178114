
.editable {
    a {
        text-decoration: none;
        color: #000;
        font-size: 14px;
        font-family: Avenir-light;
        text-align: left !important;

        &:hover {
            border-bottom: dashed 1px #ccc;
        }

        &:hover,
        &:visited,
        &:active {
            color: #000;
        }

        &.empty {
            font-style: normal;
        }
    }

    input,
    textarea {
        margin-bottom: 0px;
    }

    textarea {
        min-height: 10px;
    }
}
