#vue.map-view {
    padding-top: 65px;
    #nav-container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 30;
    }
}

.layer-details-desktop-container {
    display: block;
}

.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right {
    z-index: 50 !important;
}

@media only screen and (min-width: 768px) {
    .outer-container.map-page .inner-container #map-container.show-layers-panel {
        padding-left: 460px;
    }
    .outer-container.map-page .inner-container #map-container {
        #map {
            left: 0;
            right: 0;
            width: auto !important;
        }
        &.show-layers-panel #map {
            left: 460px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .layer-details-desktop-container {
        display: none;
    }
}

#vue .outer.layers-page.edit-map-container {
    padding-top: 100px;
}

.outer-container.map-page {
    height: 100%;
    padding-top: 0;
    .inner-container {
        height: 100%;
        position: relative;
        .layers-wrapper {
            display: none;
            .layer {
                .contours-toggles,
                .feature-details-wrapper {
                    padding: 6px 4px;
                }
                .contours-toggles,
                .feature {
                    background: $white;
                    border-radius: 4px;
                    box-shadow: 0 2px 4px 0 rgba(206, 206, 206, 0.5);
                    border: solid 1px rgba(151, 151, 151, 0.1);
                }
                .contours-toggles {
                    background: $white;
                    font-family: Avenir-lightOblique;
                    padding: 10px;
                    text-align: center;
                    .contours-toggle {
                        background: $off-white;
                        &.active {
                            background-color: $beige;
                            color: $white;
                            &:hover {
                                background-color: $beige;
                                color: $white;
                            }
                        }
                        &:hover {
                            background-color: $off-white;
                            color: $brown-grey;
                        }
                    }
                }
                .layer-header-wrapper {
                    background-color: rgba(211, 207, 194, 0.1);
                    box-shadow: inset 0 11px 26px 0 rgba(206, 206, 206, 0.5);
                    cursor: pointer;
                    padding: 6px 4px;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        background: url(#{$distImagesDir}view-icon-off.svg) center no-repeat;
                        background-size: 16px 11px;
                        content: '';
                        display: inline-block;
                        height: 44px;
                        width: 44px;
                        flex: 0 0 44px;
                        &.layer-is-visible {
                            background-image: url(#{$distImagesDir}view-icon-on.svg);
                        }
                    }
                    .panel-item-header {
                        color: #141414;
                        display: inline-block;
                        font-size: 18px;
                        font-weight: 500;
                        font-family: Avenir-lightOblique;
                        flex: 1 1 auto;
                    }
                    .arrow {
                        display: none;
                    }
                }
                ul {
                    padding-top: 10px;
                    flex-direction: column;
                    margin: 0;
                    display: flex;
                    overflow: hidden;
                    width: auto;
                    justify-content: flex-start;
                    .feature {
                        display: flex;
                        margin: 0 0 10px;
                        padding: 16px 14px;
                        position: relative;
                        width: 100%;
                        .feature-reference-url {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            background: url(#{$distImagesDir}link-icon.svg) center no-repeat;
                            background-size: 19px;
                            height: 44px;
                            width: 44px;
                        }
                        .feature-icon-wrapper-outer {
                            flex: 0 0 60px;
                            // Schools Layer Panel
                            .feature-icon-wrapper-inner {
                                color: $white;
                                display: block;
                                border-radius: 50%;
                                width: 45px;
                                height: 45px;
                                font-size: 18px;
                                font-weight: 900;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .feature-rating {
                                    width: auto;
                                    span {
                                        font-size: 8px;
                                    }
                                }
                            }
                        }
                        .feature-details-wrapper {
                            font-size: 14px;
                            width: 100%;
                            *:not(:last-child) {
                                margin-bottom: 8px;
                            }
                            .feature-header {
                                font-size: 20px;
                                font-weight: 500;
                                padding-right: 35px;
                            }
                            .feature-description {
                                color: $brown-grey;
                            }
                            .transit-point-header {
                                padding-right: 0;
                            }
                            .feature-address {
                                color: $brown-grey;
                            }
                            .feature-misc-details {
                                color: $black;
                                font-weight: 900;
                                span {
                                    font-weight: 500;
                                    color: $brown-grey;
                                }
                            }
                            .feature-last-row {
                                display: flex;
                                justify-content: space-between;
                                .feature-distance {
                                    color: $black;
                                }
                                .great-schools-logo {
                                    width: 55px;
                                    height: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }
        #map {
            .toggles {
                position: absolute;
                top: 10px;
                left: 10px;
                z-index: 1;
                .layers-panel-toggle {
                    height: 44px;
                    width: 44px;
                    background: url(#{$distImagesDir}toggle-layers-button.svg) center no-repeat;
                    background-size: 40px;
                }
            }
        }
    }
    /* .inner-container */
    /* Small and up viewport */
    @media only screen and (min-width: 576px) {
        .inner-container {
            .layers-wrapper {
                display: block;
                flex: 0 0 180px;
                height: 100%;
                max-width: 460px;
                overflow-y: scroll;
                padding: 20px 20px 0;
                position: relative;
                width: 460px;
                z-index: 2;
                .layer {
                    margin-bottom: 10px;
                    .feature-details-wrapper {
                        padding: 0;
                    }
                    .layer-header-wrapper {
                        border-radius: 4px;
                        box-shadow: none;
                        margin-bottom: 0;
                        padding: 0;
                        .panel-item-header {
                            color: #141414;
                            display: inline-block;
                            font-size: 18px;
                            font-family: Avenir-lightOblique;
                        }
                        .arrow {
                            display: inline-block;
                            height: 44px;
                            width: 44px;
                            background: url(#{$distImagesDir}right-arrow.svg) center no-repeat;
                            background-size: 14px;
                            flex: 0 0 44px;
                            &.layer-expanded {
                                background: url(#{$distImagesDir}down-arrow.svg) center no-repeat;
                            }
                        }
                    }
                }
            }
            #map-container {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                border-left: 1px solid #FFFFFF;
                box-shadow: -8px 2px 20px 0 rgba(0, 0, 0, 0.1);
                width: 100%;
                z-index: 4;
                padding-left: 0;
                box-sizing: border-box;
                pointer-events: none;
                #map {
                    width: 100%;
                    height: 100%;
                    pointer-events: auto;
                }
                .toggles {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    z-index: 1;
                    .layers-panel-toggle {
                        height: 44px;
                        width: 44px;
                        background: url(#{$distImagesDir}toggle-layers-button.svg) center no-repeat;
                        background-size: 40px;
                    }
                }
            }
        }
    }
}


/**************************************
 * Map Provider Attribution
***************************************/

.map-provider {
    bottom: 0px;
    left: 5px;
    position: absolute;
    z-index: 1;
}

// Apple
.map-provider-apple {
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    .legal-wrapper {
        left: 35px;
        position: absolute;
        top: 19px;
    }
    .legal-btn {
        color: rgba(0, 0, 0, .7);
        font: 9px "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 500;
        pointer-events: auto;
        text-decoration: underline solid #979797;
        text-shadow: 0 0 1px rgba(255, 255, 255, .85);
        white-space: nowrap;
    }
    .legal-links {
        background: #ffffff;
        border: {
            top: 1px solid rgba(0, 0, 0, .2);
            left: 1px solid rgba(0, 0, 0, .2);
            right: 1px solid rgba(0, 0, 0, .2);
        }
        bottom: -15px;
        color: #212121;
        font-size: 13px;
        left: -6px;
        position: absolute;
        white-space: nowrap;
    }
    .legal-links a {
        color: #212121;
        display: block;
        &:visited {
            color: #212121;
        }
        &:hover {
            text-decoration: none;
        }
    }
    .legal-link {
        border-bottom: 1px solid #dae1e7;
        font-weight: 500;
        padding: 5px 12px;
        &:hover {
            cursor: pointer;
        }
        &:active {
            background: rgba(0, 0, 0, .07);
        }
    }
    .close-legal-info-btn {
        background: rgba(0, 0, 0, .04);
        border-bottom: none;
        cursor: pointer;
        height: 27px;
        pointer-events: auto;
        position: relative;
        svg {
            height: 11px;
            left: 8px;
            position: absolute;
            pointer-events: none;
            top: 8px;
            width: 11px;
        }
    }
    .tomtom-link a,
    .tomtom-link a:visited {
        color: #b2b2b2;
    }
    .tomtom-logo {
        height: 22px;
        vertical-align: top;
        width: 68px;
    }
    .right-caret {
        display: inline-block;
        height: 9px;
        position: relative;
        width: 5px;
        svg {
            height: 9px;
            fill: none;
            position: absolute;
            stroke-width: 1.5;
            stroke: #8e8e8e;
            top: 1px;
            width: 7px;
        }
    }
}

// Google
.map-provider-google {
    background-color: rgba(255, 255, 255, 0.8);
    display: inline-block;
    font-size: 10px;
    height: 14px;
    line-height: 14px;
    left: unset;
    padding: 0 3px;
    right: 0;
}


/**************************************
 *
***************************************/
