.home-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 img {
        display: block;
        width: 300px;
    }

    p {
        margin: 15px auto 20px;
        font-family: 'Avenir-lightOblique';
        color: #000000;
        font-size: 24px;
    }
    .pill-btn {
        min-width: 115px;
        color: #fff !important;
    }
}
