/**********************************
 * Admin Dashboard - Users List
 **********************************/
.outer {
    .pagination-wrapper {
        align-content: stretch;
        color: #9b9b9b;
        display: flex;
        font: {
            family: Avenir-lightOblique;
            size: 12px;
            weight: 900;
        }
        justify-content: center;
        margin-top: 15px;
        text-align: center;

        div {
            cursor: pointer;
            border: {
                top: 1px;
                right: 0;
                bottom: 1px;
                left: 1px;
                style: solid;
                color:  #9b9b9b;
            }
            min-width: 43px;
            padding: 5px 10px;

            &:first-of-type {
                border-radius: 5px 0 0 5px;
            }

            &:last-of-type {
                border-radius: 0 5px 5px 0;
                border-right: 1px solid #9b9b9b;
            }

            &:hover {
                background-color: $button-group-bg-color;
                color: $button-group-text-hover;
            }

            &.active {
                background-color: $black;
                color: $white;
                cursor: default;
            }
        }
    }

    /* Small viewport */
    @media only screen and (min-width: 576px) {
        .pagination-wrapper {
            margin-top: 68px;
        }
    }
}
