.list-group-item {
  background-color: #ffffff;
  width: 100%;
  display: grid;
  grid-template-columns: 70px 100px minmax(0,1fr) 100px 80px;
  align-items: center;
  margin-bottom: 14px;
  height: 101px;
  border: solid 1px rgba(211, 207, 194, 0.49);
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  .img-wrap {
    margin-left: 23px;
    margin-right: 25px;
  }

  .thumb {
    margin: 10px;
  }

  .title-caption-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;

    .title {
      display: flex;
      color: #000000;
      width: 100%;
      margin-top: 23px;
      margin-bottom: 6px;
      text-align: left;

      .editable {

        width: 100%;

        a {
          font-family: Avenir-Medium;
          font-size: 15px;
          font-weight: 500;

          &.empty {
            font-style: normal;
          }
        }
      }
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }

    .caption {
      text-align: left;
      color: #3b3b3b;

      a {
        color: #3b3b3b;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }

      .editable textarea {
        padding: 7px 0px 7px 15px;
      }
    }
  }

  .menu-icon-wrapper {
    cursor: pointer;
    position: relative;
    margin-left: auto;
    top: unset;
    right: unset;

    .sub-menu {
      left: auto;
      right: 100%;
      margin-right: -15px;
    }
  }
}

.thumb {
  margin-right: 5px;
  display: inline-block;
  position: relative;
  color: #000;

  .thumb-img {
    object-fit: cover;
    height: 80px;
    width: 80px;
    border-radius: 5px;
    background-color: #000;
    cursor: pointer;
  }

  i.done {
    color: #CCC;
  }

  .uploading {
    opacity: 0.4;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);

    .processing {
      font-size: 14px;
      color: #fff;
    }
  }
}



.drag-handle {
  cursor: move;
  padding: 15px;
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.ghost {
  opacity: .3;
  background: #b0d5e6;
  cursor: grabbing;
}
