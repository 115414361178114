/**********************************
 * Layer Details Styling
 **********************************/
// From Desktop view down.
.layer-details {
    background: $off-white;
    height: 100%;
    padding: 20px;
    width: 460px;
    position: absolute;
    overflow-y: auto;
    z-index: 6;
}

.panel {
    background: $white;
    border: solid 1px rgba(211, 207, 194, 0.49);
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(206, 206, 206, 0.5);
    margin-bottom: 3px;
    padding: 20px;
    position: relative;

    // Top panel with Back btn.
    &.panel-control {
        align-items: center;
        display: flex;
        font-size: 18px;
        padding: 5px 12px;
        cursor: pointer;

        .panel-control-icon {
            background: url(#{$distImagesDir}back-icon.svg) left center no-repeat;
            background-size: 25px;
            height: 44px;
            width: 44px;
        }
    }

    &.mobile-panel-control {
        display: none;
    }

    h6 {
        color: $black;
        font: {
            size: 18px;
            style: oblique;
        }
        padding-right: 35px;
    }

    .feature-reference-url {
        position: absolute;
        top: 10px;
        right: 10px;
        background: url(#{$distImagesDir}link-icon.svg) center no-repeat;
        background-size: 19px;
        height: 44px;
        width: 44px;
    }

    h1 {
        font-size: 20px;
        font-family: Avenir-light;
        font-weight: 500;
        margin-bottom: 15px;
        padding-right: 30px;

        &.font-bold {
            font-weight: 700;
        }
    }

    p {
        color: $brown-grey;
        font-size: 14px;
        margin-bottom: 10px;
        overflow: hidden;
    }
}

.feature {
    display: flex;
    flex-flow: column;

    .feature-icon-wrapper-outer {
        flex: 0 0 60px;

        .feature-icon-wrapper-inner {
            color: $white;
            display: block;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            background: #777777;
            font-size: 18px;
            font-weight: 900;
            display: flex;
            justify-content: center;
            align-items: center;

            .feature-rating {
                width: auto;

                span {
                    font-size: 8px;
                }
            }

            &.circle-rating-1 {
                background-color:#f26b16
            }
            &.circle-rating-2 {
                background-color:#e78818
            }
            &.circle-rating-3 {
                background-color:#dca21a
            }
            &.circle-rating-4 {
                background-color:#d2b81b
            }
            &.circle-rating-5 {
                background-color:#bdc01e
            }
            &.circle-rating-6 {
                background-color:#a3be1f
            }
            &.circle-rating-7 {
                background-color:#86b320
            }
            &.circle-rating-8 {
                background-color:#6ba822
            }
            &.circle-rating-9 {
                background-color:#559f24
            }
            &.circle-rating-10 {
                background-color:#439326
            }
        }
    }

    .feature-details-wrapper {
        font-size: 14px;
        width:100%;
        cursor: default;

        *:not(:last-child):not(.m-0):not(.mb-0) {
            margin-bottom: 8px;
        }


        .feature-misc-details {
            color: $black;
            font-weight: 900;

            span {
                font-weight: 500;
                color: $brown-grey;
            }
        }

        .feature-last-row {
            display: flex;
            justify-content: space-between;

            .feature-distance {
                color: $black;
            }

            .great-schools-logo {
                width: 55px;
                height: 22px;
            }
        }
    }

/**********************************
 * Temp Housing Details Styling
 **********************************/
    .temp-housing-details {
        margin-bottom: 0 !important;
        .swiper-slide {
            width: 100% !important;
        }
        .carousel-img {
            border-radius: 10px;
            object-fit: cover;
            height: 175px;
            width: 375px;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }
        .swiper-pagination {
            position: static;
            margin-bottom: 13px;
            .swiper-pagination-bullet {
                height: 10px;
                width: 10px;
                background-color: #cccccc;
                opacity: unset;
                margin-right: 15px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
            .swiper-pagination-bullet-active {
                background-color: #9b9b9b !important;
            }
        }
        .feature-header {
            font-family: Avenir-Heavy;
            font-size: 19px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.26;
            letter-spacing: normal;
            color: #000;
        }
        .star-rating {
            margin-right: 7px;
            &:last-of-type {
                margin-right: 0;
            }
        }
        .feature-last-row {
            padding: 17px 10px 34px 0;
            margin-bottom: 0 !important;
            .feature-description {
                font-family: Avenir-Book;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.26;
                letter-spacing: normal;
                color: #000;
                margin: 0;
                @media only screen and (max-width: 500px) {
                    font-size: 14px;
                }
            }
        }
        .btn-wrapper {
            @media only screen and (max-width: 767px) {
                text-align: center;
                margin-bottom: 10px;
            }
            button {
                height: 44px;
                border-radius: 41.5px;
                background-color: #141414;
                color: #fafafa;
                width: 331px;
                @media only screen and (max-width: 460px) {
                    width: 100%;
                }
                &.disabled-button {
                    cursor: default;
                    opacity: 0.5;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .layer-details {
        bottom: 0;
        height: auto;
        overflow: hidden;
        padding: 10px;
        width: 100%;
        z-index: 5;
        transition: all 1s ease-in;
    }

    .layer-details .panel-control {
        display: none;
    }

    .layer-details .mobile-panel-control {
        display: flex;
        padding-top: 0;
        padding-bottom: 0;
        height: 45px;
        border: none;
        align-items: start;

        .panel-control-icon {
            background-position: left top;
        }
    }

    .layer-details .feature {
        margin-bottom: 0;
    }

    .layer-details .feature-description {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
